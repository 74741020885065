import $ from "jquery";
import createSortable from "@/utils/sortable";


document.addEventListener("turbo:load", function() {
  if ($("#draggableCabinet").length > 0) {
    setTimeout(() => {
      startCabinetSorter();
    }, 50); // Small delay to ensure everything is fully initialized
  }
});


function startCabinetSorter() {
  var sortable = createSortable(draggableCabinet, {
    onStart: function(evt) {
      // Force a reflow to ensure the element styles are updated immediately
      evt.item.offsetHeight;
      $(".draghandle").on("click", function(event) {
        $(evt.item).find("a").css("cursor", "grabbing");
        event.preventDefault();
      });
    },
    onEnd: function() {
      $(".draghandle").off("click");
    },
    onUpdate: function(evt) {
      var ourCollection = $(evt.item)
      var collectionId = evt.item.dataset.id;
      var collectionNewPosition = evt.newIndex;

      $.ajax({
        url: $("#draggableCabinet").data("url"),
        type: "POST",
        dataType: "script",
        data: {
          id: collectionId,
          newCabinetPosition: collectionNewPosition
        }
      });
    }
  }, true);
}
