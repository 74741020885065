import $ from "jquery";
import createSortable from "@/utils/sortable";

document.addEventListener("turbo:load", function() {
  if ($("#draggableCatalogue").length > 0) {
    startCatalogueSorter();
  }

  if($("#js-coin-show").length > 0) {
    clickLeftOrRight();
  }

 
});

function startCatalogueSorter() {
  createSortable(draggableCatalogue, {
    onUpdate: function(evt) {
      var ourCoin = $(evt.item)
      var earliestCoinPosition = getEarliestCoinPosition(ourCoin)
      var coinId = evt.item.dataset.id;
      var coinNewPosition = evt.newIndex + earliestCoinPosition;

      $.ajax({
        url: $("#draggableCatalogue").data("url"),
        type: "POST",
        dataType: "script",
        data: {
          id: coinId,
          newCataloguePosition: coinNewPosition
        }
      });
    }
  }, true);
}

function getEarliestCoinPosition(ourCoin) {
  var earliestPosition = ourCoin.siblings()[0].getElementsByClassName("js-cat-position")[0].dataset.position;
  return parseInt(earliestPosition, 10)
}


function clickLeftOrRight() {
  document.onkeydown = checkKey;
}

function checkKey(e) {
  e = e || window.event;

  if (e.keyCode == "37") {
    const leftArrow = document.getElementById("js-previous-coin");
    leftArrow && leftArrow.click();
  }
  else if (e.keyCode == "39") {
    const rightArrow = document.getElementById("js-next-coin");
    rightArrow && rightArrow.click();
  }
}


document.addEventListener('keydown', function(event) {
  // Check if the left arrow key is pressed (key code 37)
  if (event.key === "ArrowLeft") {
    const previousLink = document.getElementById('previous-coin-link');
    if (previousLink) {
      Turbo.visit(previousLink.href);  // Use Turbo to navigate
    }
  }
  
  // Check if the right arrow key is pressed (key code 39)
  if (event.key === "ArrowRight") {
    const nextLink = document.getElementById('next-coin-link');
    if (nextLink) {
      Turbo.visit(nextLink.href);  // Use Turbo to navigate
    }
  }
});